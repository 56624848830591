import React, { useState, useEffect } from 'react';
import { httpClient } from '../axios';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import { useRef } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { useMemo } from 'react';

const StyledPopupEdit = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: fit-content;
    max-height: 90vh; // Set a maximum height (80% of the viewport height)
    overflow-y: auto; // Enable vertical scrolling when content overflows
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const ViewQuestions = () => {
  const [questionList, setQuestionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const navigate = useNavigate();

  const [subjectList, setsubjectList] = useState([]);
  const [entExamsList, setEntExamsList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [subtopicList, setSubTopicList] = useState([]);

  let { userId } = useParams();
  let { colorCode } = useParams();

  const [filtertext, setFiltertext] = useState('');

  const [status, setStatus] = useState('Accepted');

  const GetActiveQuestions = async () => {
    try {
      const res = await httpClient.get(
        `api/question/getByUserId/${userId}/${status}`
      );
      setQuestionList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  // useEffect(() => {
  //   GetActiveQuestionsFromSearchString();
  // }, [searchTerm, page, size]);

  const [subjectStr, setSubjectStr] = useState('');
  const [chapterStr, setChapterStr] = useState('');

  const GetActiveQuestionsFromSearchString = async () => {
    try {
      const res = await httpClient.get(
        `/api/question/search?searchTerm=${searchTerm}&page=${page}&size=${size}&subject=${subjectStr}&chapter=${chapterStr}`
      );
      setQuestionList(res.data.questions);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetActiveQuestions();
  }, [status]);

  const config = {
    // allowedContent: true,
    extraPlugins: 'image2,mathjax',
    // removeButtons: 'Paste,PasteText,PasteFromWord',
    // mathJaxLib:
    //   'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js?config=TeX-MML-AM_CHTML',
    versionCheck: false,
    height: '400px',
    // Image settings
    image2_alignClasses: [
      'image-align-left',
      'image-align-center',
      'image-align-right',
    ], // optional, for alignment
  };

  const postImageApi = async (blobs, data, type) => {
    // Use Promise.all to wait for all file upload promises to resolve
    const uploadedUrls = await Promise.all(
      blobs.map(async (blob) => {
        let formData = new FormData();
        formData.append('file', blob);

        try {
          const res = await httpClient.post(
            '/api/v1/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          console.log('File uploaded successfully');
          console.log(res);

          // Return the uploaded file URL from the response
          return res.data.url;
        } catch (err) {
          console.error('File upload failed', err);
          throw err; // Optional: You could handle failed uploads differently
        }
      })
    );
    console.log('uploadedUrls', uploadedUrls);
    console.log('data', data);

    let finalData = replaceImageSrc(uploadedUrls, data);

    // setarrayWithLinks(uploadedUrls);

    console.log('finalData', finalData);

    if (type === 'question') {
      setEditObjTemp((prev) => ({
        ...prev,
        question: finalData,
      }));
    } else if (type === 'option1') {
      setEditObjTemp((prev) => ({
        ...prev,
        option1: finalData,
      }));
    } else if (type === 'option2') {
      setEditObjTemp((prev) => ({
        ...prev,
        option2: finalData,
      }));
    } else if (type === 'option3') {
      setEditObjTemp((prev) => ({
        ...prev,
        option3: finalData,
      }));
    } else if (type === 'option4') {
      setEditObjTemp((prev) => ({
        ...prev,
        option4: finalData,
      }));
    } else if (type === 'explanation') {
      setEditObjTemp((prev) => ({
        ...prev,
        explanation: finalData,
      }));
    } else if (type === 'solution') {
      setEditObjTemp((prev) => ({
        ...prev,
        solution: finalData,
      }));
    }

    // setData(finalData);
    // Return an array of URLs after all uploads have finished
    // return uploadedUrls;
  };

  function replaceImageSrc(uploadedUrls, data) {
    // Create a regular expression to match the img tags with src attributes
    const imgRegex = /<img[^>]+src="[^"]*"[^>]*>/g;

    // Find all img tags in the data string
    let imgTags = data.match(imgRegex);

    // Loop through each img tag and replace the src with the corresponding URL from uploadedUrls
    if (imgTags && uploadedUrls.length === imgTags.length) {
      imgTags.forEach((imgTag, index) => {
        const newImgTag = imgTag.replace(
          /src="[^"]*"/,
          `src="${uploadedUrls[index]}"`
        );
        data = data.replace(imgTag, newImgTag);
      });
    } else {
      console.warn(
        'The number of uploaded URLs and image tags do not match.'
      );
    }

    return data;
  }

  const editorRef = useRef(null);
  const editorRef1 = useRef(null);
  const editorRef2 = useRef(null);
  const editorRef3 = useRef(null);
  const editorRef4 = useRef(null);
  const editorRef5 = useRef(null);

  const handleDelete = async () => {
    try {
      const res = await httpClient.delete(
        `api/question/delete/${questionToDelete}`
      );
      GetActiveQuestions();
      setOpen(false);
    } catch (error) {
      console.log('Error deleting question:', error);
    }
  };

  const handleClickOpen = (questionId) => {
    setQuestionToDelete(questionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuestionToDelete(null);
  };

  const [editObj, setEditObj] = useState();
  const [editObjTemp, setEditObjTemp] = useState();

  useEffect(() => {
    console.log('editObjTemp', editObjTemp);
  }, [editObjTemp]);
  // Navigate to AddQuestion page with the current question object
  const handleEdit = (obj) => {
    console.log(obj);
    setEditObj(obj); // Update the object to edit
    setEditObjTemp(obj); // Update the object to edit

    setOpenEdit(true); // Open the popup
  };

  useEffect(() => {
    if (openEdit && editorRef.current && editObj?.question) {
      // Set the editor content dynamically
      editorRef.current.setData(editObj.question);
    }
  }, [editObj?.question, openEdit]); // Re-run when `editObj.question` or `openEdit` changes

  useEffect(() => {
    GetActiveQuestions();
  }, []);

  useEffect(() => {
    handleDelete();
  }, []);

  const handleFilterChange = (e) => {
    setFiltertext(e.target.value);
  };
  const handleFilterChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredQuestions = questionList.filter((question) =>
    question.question.toLowerCase().includes(filtertext.toLowerCase())
  );

  // Function to convert base64 to Blob
  function base64ToBlob(base64Data, contentType) {
    try {
      // Ensure the data is in the expected Base64 format
      if (!base64Data.includes(',')) {
        throw new Error('Invalid Base64 format: Missing data prefix');
      }
      const base64String = base64Data.split(',')[1]; // Extract the Base64 portion
      const byteCharacters = atob(base64String); // Decode Base64 string
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    } catch (error) {
      console.error(
        'Error converting Base64 to Blob:',
        error.message
      );
      return null; // Return null if conversion fails
    }
  }

  // Function to convert an array of base64 strings to an array of Blobs
  function convertBase64ArrayToBlobs(base64Array, contentType) {
    return base64Array
      .map((base64String) => {
        const blob = base64ToBlob(base64String, contentType);
        if (!blob) {
          console.warn(
            'Invalid Base64 string skipped:',
            base64String
          );
        }
        return blob;
      })
      .filter((blob) => blob !== null); // Exclude invalid Blobs
  }

  const extractImageSrc = (htmlString) => {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Get all <img> elements from the parsed HTML
    const imgElements = tempDiv.getElementsByTagName('img');

    // Extract the src attributes into an array
    const imgSrcArray = Array.from(imgElements).map((img) => img.src);

    return imgSrcArray;
  };

  // Memoize editObj to avoid unnecessary renders
  const stableEditObj = useMemo(() => editObj, [editObj]);

  const [loader, setloader] = useState(false);

  const EditFunc = async () => {
    setloader(true);
    try {
      const res = await httpClient.put(
        `/api/question/update`,
        editObjTemp
      );
      GetActiveQuestions();
      console.log(res.data);
      // setQuestionList((prevList) =>
      //   prevList.filter((question) => question.questionId !== questionToDelete)
      // );
      setOpenEdit(false);
    } catch (error) {
      console.log('Error updating question:', error);
    } finally {
      setloader(false);
    }
  };

  const statusList = ['Accepted', 'Rejected', 'pending'];

  useEffect(() => {
    GetAllActiveEntExams();
  }, [editObjTemp?.['standardId']]);

  useEffect(() => {
    GetAllActiveSubjectsByStandardId();
    // console.log(editObjTemp);
  }, [editObjTemp?.['standardId']]);

  useEffect(() => {
    GetChaptersBySubjectId();
    // console.log(editObjTemp);
  }, [editObjTemp?.['subjectId']]);

  useEffect(() => {
    GetTopicsByChapterId();
    // console.log(editObjTemp);
  }, [editObjTemp?.['chapterId']]);

  useEffect(() => {
    GetSubTopicsByTopicId();
    // console.log(editObjTemp);
  }, [editObjTemp?.['topicId']]);

  useEffect(() => {
    GetStandardsByEntranceId();
    // GetAllActiveEntExams();
    GetQuestionLevel();
    GetQuestionType();
    GetYearOfAppearance();
    GetAllActivePattern();
    // GetAllActiveStandards();
    // GetAllActiveSubjects();
  }, []);

  const GetAllActiveSubjectsByStandardId = async () => {
    try {
      const res = await httpClient.get(
        `/api/subject/standardwisesubject/${editObjTemp.standardId}`
      );
      setsubjectList(res?.data?.subjectMasters);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllActiveEntExams = async () => {
    try {
      const res = await httpClient.get(
        `/api/standard/standardwiseentranceexam/${editObjTemp.standardId}`
      );
      setEntExamsList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetStandardsByEntranceId = async () => {
    try {
      const res = await httpClient.get(
        `api/standard/getallstandards`
      );
      setStandardList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetChaptersBySubjectId = async () => {
    try {
      const res = await httpClient.get(
        `/api/chapter/subjectwisechapter/${editObjTemp?.subjectId}`
      );
      setChapterList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetTopicsByChapterId = async () => {
    try {
      const res = await httpClient.get(
        `/api/topic/chapterwisetopics/${editObjTemp?.chapterId}`
      );
      setTopicList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetSubTopicsByTopicId = async () => {
    try {
      const res = await httpClient.get(
        `/api/subtopic/topicwisesubtopics/${editObjTemp?.topicId}`
      );
      setSubTopicList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [errors, setErrors] = useState({
    standardId: '', // Error for Standard dropdown
    entranceExamId: '', // Error for Exam dropdown
    subjectId: '', // Error for Subject dropdown
    chapterId: '', // Error for Chapter dropdown
    topicId: '', // Error for Topic dropdown
    questionTypeId: '', // Error for Question drop
    questionLevelId: '', // Error for Question
    marks: '',
    patternId: '', // Error for Pattern dropdown
    question: '', // Error for Question
    option1: '', // Error for
    option2: '', // Error for
    option3: '', // Error for
    option4: '', // Error for
    explanation: '', // Error for
    solution: '', // Error for
    questionCategory: '',
  });

  const validateField = (fieldName, value) => {
    const errorMessages = {
      id: 'ID is required',
      entranceExamId: 'Entrance Exam is required',
      standardId: 'Standard is required',
      subjectId: 'Subject is required',
      chapterId: 'Chapter is required',
      marks: 'Marks must be greater than 0',
      // topicId: 'Topic is required',
      // subTopicId: 'Sub-topic is required',
      question: 'Question is required',
      option1: 'Option 1 is required',
      option2: 'Option 2 is required',
      option3: 'Option 3 is required',
      option4: 'Option 4 is required',
      // yearOfAppearanceId: 'Year of Appearance is required',
      questionLevelId: 'Question Level is required',
      questionTypeId: 'Question Type is required',
      explanation: 'Explanation is required',
      patternId: 'Pattern is required',
      solution: 'Provide either a solution or multi-answers',
      questionCategory: 'Question Category is required',
    };

    let errorMessage = '';

    if (fieldName === 'marks' && (value === null || value <= 0)) {
      errorMessage = errorMessages.marks;
    } else if (
      fieldName === 'solution' &&
      !value?.trim() &&
      (!editObjTemp.multiAnswers ||
        editObjTemp.multiAnswers.length === 0)
    ) {
      errorMessage = errorMessages.solution;
    } else if (!value?.trim()) {
      errorMessage = errorMessages[fieldName];
    }

    setErrors((prev) => ({ ...prev, [fieldName]: errorMessage }));
    return !errorMessage; // Return true if no error
  };

  const [YearofAppearList, setYearofAppearList] = useState([]);
  const [QuestionTypeList, setQuestionTypeList] = useState([]);
  const [QuestionLevelList, setQuestionLevelList] = useState([]);
  const [PatternsList, setPatternsList] = useState([]);

  const GetYearOfAppearance = async () => {
    try {
      const res = await httpClient.get(
        `/api/yearofappearance/getallactive`
      );
      setYearofAppearList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetQuestionType = async () => {
    try {
      const res = await httpClient.get(
        `/api/questiontype/getallactive`
      );
      setQuestionTypeList(res.data);
      console.log('questiontype/getallactive', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetQuestionLevel = async () => {
    try {
      const res = await httpClient.get(
        `/api/questionlevel/getallactive`
      );
      setQuestionLevelList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [questionType, setQuestionType] = useState('');

  const GetAllActivePattern = async () => {
    try {
      const res = await httpClient.get(`/api/pattern/getallactive`);
      setPatternsList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const validateFields = () => {
    const fieldErrors = {};

    if (!editObjTemp.id) fieldErrors.id = 'ID is required';
    if (!editObjTemp.entranceExamId)
      fieldErrors.entranceExamId = 'Entrance Exam is required';
    if (!editObjTemp.standardId)
      fieldErrors.standardId = 'Standard is required';
    if (!editObjTemp.subjectId)
      fieldErrors.subjectId = 'Subject is required';
    if (!editObjTemp.chapterId)
      fieldErrors.chapterId = 'Chapter is required';
    if (editObjTemp.marks === null || editObjTemp.marks <= 0) {
      fieldErrors.marks = 'Marks must be greater than 0';
    }
    // if (!editObjTemp.topicId)
    //   fieldErrors.topicId = 'Topic is required';
    // if (!editObjTemp.subTopicId)
    //   fieldErrors.subTopicId = 'Sub-topic is required';
    if (!editObjTemp.question?.trim())
      fieldErrors.question = 'Question is required';
    if (!editObjTemp.option1?.trim())
      fieldErrors.option1 = 'Option 1 is required';
    if (!editObjTemp.option2?.trim())
      fieldErrors.option2 = 'Option 2 is required';
    if (!editObjTemp.option3?.trim())
      fieldErrors.option3 = 'Option 3 is required';
    if (!editObjTemp.option4?.trim())
      fieldErrors.option4 = 'Option 4 is required';
    // if (!editObjTemp.yearOfAppearanceId)
    //   fieldErrors.yearOfAppearanceId =
    //     'Year of Appearance is required';
    if (!editObjTemp.questionLevelId)
      fieldErrors.questionLevelId = 'Question Level is required';
    if (!editObjTemp.questionTypeId)
      fieldErrors.questionTypeId = 'Question Type is required';
    if (!editObjTemp.explanation?.trim())
      fieldErrors.explanation = 'Explanation is required';
    if (!editObjTemp.patternId)
      fieldErrors.patternId = 'Pattern is required';
    if (!editObjTemp.questionCategory)
      fieldErrors.questionCategory = 'Question Category is required';

    // At least one of these must be valid
    if (
      !editObjTemp.solution?.trim() &&
      (!editObjTemp.multiAnswers ||
        editObjTemp.multiAnswers.length === 0)
    ) {
      fieldErrors.solution =
        'Provide either a solution or multi-answers';
    }

    // Update errors state
    setErrors(fieldErrors);

    // Return false if there are any errors
    return Object.keys(fieldErrors).length === 0;
  };

  const QuestionCategoryList = [
    {
      questionCategoryId: 1,
      questionCategoryName: 'Numerical',
    },
    {
      questionCategoryId: 2,
      questionCategoryName: 'Theoretical',
    },
  ];

  const SetMarks = (e) => {
    let val = Number(e.target.value); // Convert to number

    if (val >= 0) {
      setEditObjTemp((prev) => ({
        ...prev,
        marks: val,
      }));
    }
  };

  const handleOptionChange = (e) => {
    setEditObjTemp((prev) => ({
      ...prev,
      multiAnswers: [e.target.value],
    }));
  };

  const handleToggle = (option) => {
    if (editObjTemp?.multiAnswers?.includes(option)) {
      const tempArray = editObjTemp?.multiAnswers.filter(
        (item) => item !== option
      );

      setEditObjTemp((prev) => ({
        ...prev,
        multiAnswers: tempArray,
      }));
    } else {
      setEditObjTemp((prev) => ({
        ...prev,
        multiAnswers: [...prev.multiAnswers, option],
      }));
    }
  };

  const options = [
    { id: 1, label: 'option1' },
    { id: 2, label: 'option2' },
    { id: 3, label: 'option3' },
    { id: 4, label: 'option4' },
  ];

  return (
    <>
      <p
        className='text-[28px]
       text-[#757575]
       font-thin text-start
        py-[16px] pl-[10px]'
      >
        View{' '}
        <span className='text-[#4E54C8] font-light underline'>
          Questions
        </span>
      </p>

      {/* filter fild */}
      <div className='px-[10px] py-[10px] flex gap-[20px] justify-end fixed top-0 right-0'>
        <div>
          <select
            className='form-select h-[46px] w-[100%] max-w-[300px] mt-[10px]'
            aria-label='Default select example'
            value={status || ''}
            onChange={(e) => {
              const selectedStatus = e.target.value;

              const selectedstatus = statusList?.find(
                (exam) => exam === selectedStatus
              );

              if (selectedstatus) {
                setStatus(selectedstatus);
              }
            }}
          >
            <option value=''>Select Type of Question</option>

            {statusList?.map((obj, index) => (
              <option key={index} value={obj}>
                {obj}
              </option>
            ))}
          </select>
        </div>

        <div>
          <input
            type='text'
            placeholder='Filter questions...'
            value={filtertext}
            onChange={handleFilterChange}
            className='border border-gray-300 p-[10px] rounded-md w-[100%] mr-48 mt-[10px]'
          />
        </div>

        <div>
          <input
            type='text'
            placeholder='Search questions...'
            value={searchTerm}
            onChange={handleFilterChangeSearch}
            className='border border-gray-300 p-[10px] rounded-md w-[100%] mr-48 mt-[10px]'
          />
        </div>

        <button
          className='bg-[blue] text-[white] h-[46px] mt-[10px] px-[10px] rounded-[10px]'
          onClick={GetActiveQuestionsFromSearchString}
        >
          Search
        </button>
      </div>

      {questionList?.length > 0 ? (
        <div className='px-[10px]'>
          {questionList?.map((obj) => (
            <>
              <div
                className='flex flex-col 
             border border-[#EFEFEF]
              text-[14px] py-[25px]'
                style={{
                  display: 'flex ',
                  width: '100%',
                }}
                key={obj.questionId}
              >
                <div
                  className='flex items-center  
             gap-[25px]
             p-[15px]'
                  style={{ overflowX: 'auto' }}
                >
                  <div style={{ fontSize: '14px' }}>
                    {obj.questionId}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                    }}
                  >
                    <div className='max-w-[1070px] w-[100%] '>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: obj.question,
                        }}
                      ></p>
                    </div>
                    <div
                      style={{
                        maxWidth: '850px',
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '28px',
                      }}
                    >
                      {[
                        'option1',
                        'option2',
                        'option3',
                        'option4',
                      ].map((option, index) => (
                        <div
                          key={index}
                          className={`p-[8px] flex-wrap max-w-[400px] w-[100%]`}
                          style={{
                            // width: '400px',
                            border: '1.5px solid',
                            borderColor: obj.multiAnswers?.includes(
                              option
                            )
                              ? '#22C55E'
                              : '#EFEFEF',
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: obj[option],
                          }}
                        />
                      ))}
                    </div>
                    <p>Explanation:</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: obj.explanation,
                      }}
                    ></p>

                    <div>
                      <span>
                        Exam: {obj?.entranceExamName} (
                        {obj?.yearOfAppearance})
                      </span>{' '}
                      <br />
                      <span>Subject: {obj?.subjectName}</span>
                    </div>
                  </div>

                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginRight: '15px',
                    }}
                  >
                    <div></div>

                    <div style={{ display: 'flex' }}>
                      <div
                        style={{
                          height: '30px',
                          width: '35px',
                          border: '2px solid #EFEFEF',
                          borderRadius: '10px 0 0 10px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          handleEdit(obj);
                        }} // Navigate to AddQuestion page
                      >
                        <i className='fa-solid fa-pen-to-square'></i>
                      </div>
                      <div
                        style={{
                          height: '30px',
                          width: '35px',
                          border: '2px solid #EFEFEF',
                          borderRadius: '0px 10px 10px 0px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleClickOpen(obj.questionId)
                        } // Open the delete confirmation popup
                      >
                        <i className='fa-solid fa-trash'></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <StyledPopupEdit
                open={openEdit}
                closeOnDocumentClick={false}
                position={'top center'}
                onClose={() => {
                  setOpenEdit(false);
                  document.body.classList.remove('body-no-scroll');
                  // setOpenOnMountPop2(true);
                }}
              >
                <div
                  className='relative'
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                    width: '1000px',
                    textAlign: 'center',
                  }}
                >
                  <i
                    onClick={() => setOpenEdit(false)}
                    className='fa-solid fa-xmark absolute top-[0px] right-[0px] cursor-pointer'
                  ></i>

                  <div className='flex justify-between text-[21px] mt-[15px]'>
                    <span className='text-[#757575]'>
                      Question Id. {editObj?.questionId}
                    </span>
                    <span className='text-[#757575]'>
                      Exam: {editObj?.entranceExamName}
                    </span>
                  </div>
                  <span className='text-[#757575] text-[21px] flex justify-start my-[15px]'>
                    Subject: {editObj?.subjectName}
                  </span>

                  <div className='flex justify-between'></div>

                  <div className='flex flex-wrap justify-start mt-[20px] gap-[20px]'>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col'
                    >
                      <p className='text-[#757575] text-start'>
                        Standard Name
                      </p>
                      <select
                        className='form-select w-[300px]'
                        aria-label='Default select example'
                        value={editObjTemp?.standardId || ''}
                        onChange={(e) => {
                          const selectedStandardId = e.target.value;
                          console.log(
                            'Selected ID:',
                            selectedStandardId
                          );

                          const selectedStandard = standardList.find(
                            (exam) =>
                              exam.standardId ===
                              Number(selectedStandardId)
                          );

                          // console.log(
                          //   'Selected Standard:',
                          //   selectedStandard
                          // );

                          if (selectedStandard) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              standardId: selectedStandard.standardId,
                            }));
                          }
                          validateField(
                            'standardId',
                            selectedStandardId
                          );
                        }}
                        onBlur={(e) =>
                          validateField('standardId', e.target.value)
                        }
                      >
                        <option style={{ color: '#9ca3af' }} value=''>
                          Select Standard
                        </option>

                        {standardList?.map((obj, index) => (
                          <option key={index} value={obj.standardId}>
                            {obj.standardName}
                          </option>
                        ))}
                      </select>
                      {errors.standardId && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.standardId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>

                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col'
                    >
                      <p className='text-[#757575] text-start'>
                        Exam Name
                      </p>
                      <select
                        className='form-select w-[300px]'
                        aria-label='Default select example'
                        value={editObjTemp?.entranceExamId || ''} // Set the default selected value here
                        onChange={(e) => {
                          const selectedExamName = e.target.value;
                          const selectedExam = entExamsList?.find(
                            (exam) =>
                              exam.entranceExamId ===
                              Number(selectedExamName)
                          );

                          if (selectedExam) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              entranceExamId:
                                selectedExam.entranceExamId,
                            }));
                          }
                          validateField(
                            'entranceExamId',
                            selectedExamName
                          );
                        }}
                        onBlur={(e) =>
                          validateField(
                            'entranceExamId',
                            e.target.value
                          )
                        }
                      >
                        <option value=''>Select exam</option>

                        {entExamsList?.length > 0 &&
                          entExamsList?.map((obj, index) => (
                            <option
                              key={index}
                              value={obj.entranceExamId}
                            >
                              {obj.entranceExamName}
                            </option>
                          ))}
                      </select>
                      {errors.entranceExamId && (
                        <p className='text-red-500 text-sm text-start'>
                          {errors.entranceExamId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col text-start'
                    >
                      <p className='text-[#757575]'>Subject Name</p>
                      <select
                        className='form-select w-[300px]'
                        aria-label='Default select example'
                        value={editObjTemp?.subjectId || ''} // Set the default selected value here
                        onChange={(e) => {
                          const selectedSubjectName = e.target.value;

                          const selectedSubject = subjectList.find(
                            (exam) =>
                              exam.subjectId ===
                              Number(selectedSubjectName)
                          );

                          if (selectedSubject) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              subjectId: selectedSubject.subjectId,
                            }));
                          }
                          validateField(
                            'subjectId',
                            selectedSubjectName
                          );
                        }}
                        onBlur={(e) =>
                          validateField('subjectId', e.target.value)
                        }
                      >
                        <option value=''>Select Subject</option>

                        {subjectList?.map((obj, index) => (
                          <option key={index} value={obj.subjectId}>
                            {obj.subjectName}
                          </option>
                        ))}
                      </select>
                      {errors.subjectId && (
                        <p className='text-red-500 text-sm text-start'>
                          {errors.subjectId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>

                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col'
                    >
                      <p className='text-[#757575]  text-start'>
                        Chapter Name
                      </p>
                      <select
                        className='form-select w-[300px]'
                        aria-label='Default select example'
                        value={editObjTemp?.chapterId || ''} // Set the default selected value here
                        onChange={(e) => {
                          const selectedChapterName = e.target.value;

                          const selectedChapter = chapterList.find(
                            (exam) =>
                              exam.chapterId ===
                              Number(selectedChapterName)
                          );

                          if (selectedChapter) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              chapterId: selectedChapter.chapterId,
                            }));
                          }
                          validateField(
                            'chapterId',
                            selectedChapterName
                          );
                        }}
                        onBlur={(e) =>
                          validateField('chapterId', e.target.value)
                        }
                      >
                        <option value=''>Select Chapter</option>

                        {chapterList?.map((obj, index) => (
                          <option key={index} value={obj.chapterId}>
                            {obj.chapterName}
                          </option>
                        ))}
                      </select>
                      {errors.chapterId && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.chapterId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col'
                    >
                      <p className='text-[#757575]  text-start'>
                        Question type
                      </p>
                      <select
                        className='form-select w-[300px]'
                        aria-label='Default select example'
                        value={editObjTemp?.questionTypeId || ''} // Automatically updates when questionTypeId changes
                        onChange={(e) => {
                          const selectedQuestionType = e.target.value;

                          // Find the selected question type object from the list
                          const selectedquestionType =
                            QuestionTypeList.find(
                              (exam) =>
                                exam.questionTypeId ===
                                Number(selectedQuestionType)
                            );

                          if (selectedquestionType) {
                            // Update the createQuestion state
                            setEditObjTemp((prev) => ({
                              ...prev,
                              questionTypeId:
                                selectedquestionType.questionTypeId,
                            }));

                            // dispatch(setQuestion(payload));

                            // Update the question type (e.g., lowercase version)
                            setQuestionType(
                              selectedquestionType?.questionType?.toLowerCase()
                            );
                          }

                          // Validate the field
                          validateField(
                            'questionTypeId',
                            selectedQuestionType
                          );
                        }}
                        onBlur={(e) =>
                          validateField(
                            'questionTypeId',
                            e.target.value
                          )
                        } // Validate on blur
                      >
                        <option value=''>
                          Select Type of Question
                        </option>

                        {QuestionTypeList?.map((obj, index) => (
                          <option
                            key={index}
                            value={obj?.questionTypeId}
                          >
                            {obj?.questionType}
                          </option>
                        ))}
                      </select>
                      {errors.questionTypeId && (
                        <p className='text-red-500 text-sm text-start'>
                          {errors.questionTypeId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>

                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col  text-start'
                    >
                      <p className='text-[#757575]'>
                        Level of Question
                      </p>
                      <select
                        className='form-select w-[300px]'
                        value={editObjTemp?.questionLevelId || ''}
                        aria-label='Default select example'
                        onChange={(e) => {
                          const selectedQuestionLevel =
                            e.target.value;

                          const selectedquestionLevel =
                            QuestionLevelList?.find(
                              (exam) =>
                                exam.questionLevelId ===
                                Number(selectedQuestionLevel)
                            );

                          if (selectedquestionLevel) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              questionLevelId:
                                selectedquestionLevel.questionLevelId,
                            }));
                          }
                          validateField(
                            'questionLevelId',
                            selectedQuestionLevel
                          );
                        }}
                        onBlur={(e) =>
                          validateField(
                            'questionLevelId',
                            e.target.value
                          )
                        }
                      >
                        <option value=''>
                          Select Level of Question
                        </option>

                        {QuestionLevelList?.map((obj, index) => (
                          <option
                            key={index}
                            value={obj?.questionLevelId}
                          >
                            {obj?.questionLevel}
                          </option>
                        ))}
                      </select>
                      {errors.questionLevelId && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.questionLevelId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col  text-start'
                    >
                      <p className='text-[#757575]'>Marks</p>
                      <input
                        className='h-[38px] w-[300px] px-[10px]  border border-[#ced4da]'
                        type='number'
                        placeholder='Enter marks'
                        onChange={SetMarks}
                        value={editObjTemp?.marks}
                        onBlur={(e) =>
                          validateField('marks', e.target.value)
                        }
                      />
                      {errors.marks && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.marks}
                        </p>
                      )}{' '}
                    </div>

                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col  text-start'
                    >
                      <p className='text-[#757575]'>
                        Question Category
                      </p>
                      <select
                        className='form-select w-[300px]'
                        value={editObjTemp?.questionCategory || ''}
                        aria-label='Default select example'
                        onChange={(e) => {
                          const selectedQuestionCategory =
                            e.target.value;

                          const selectedquestionCategory =
                            QuestionCategoryList?.find(
                              (exam) =>
                                exam.questionCategoryName ===
                                selectedQuestionCategory
                            );

                          if (selectedquestionCategory) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              questionCategory:
                                selectedquestionCategory.questionCategoryName,
                            }));
                          }
                          validateField(
                            'questionCategory',
                            selectedQuestionCategory
                          );
                        }}
                        onBlur={(e) =>
                          validateField(
                            'questionCategory',
                            e.target.value
                          )
                        }
                      >
                        <option value=''>
                          Select Category of Question
                        </option>

                        {QuestionCategoryList?.map((obj, index) => (
                          <option
                            key={index}
                            value={obj?.questionCategoryName}
                          >
                            {obj?.questionCategoryName}
                          </option>
                        ))}
                        {/* {QuestionCategoryList?.map((obj, index) => (
                    <option key={index} value={obj?.questionCategoryId}>
                      {obj?.questionCategory}
                    </option>
                  ))} */}
                      </select>
                      {/* <input
                  className='h-[38px] w-[300px] px-[10px]  border border-[#ced4da]'
                  type='text'
                  placeholder='Enter Question Category'
                  onChange={(e) => {
                    setCreateQuestion((prev) => ({
                      ...prev,
                      questionCategory: e.target.value,
                    }));
                  }}
                  value={createQuestion?.questionCategory}
                  onBlur={(e) =>
                    validateField('questionCategory', e.target.value)
                  }
                /> */}
                      {errors.questionCategory && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.questionCategory}
                        </p>
                      )}{' '}
                    </div>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex flex-col'
                    >
                      <p className='text-[#757575]  text-start'>
                        Pattern
                      </p>
                      <select
                        className='form-select w-[300px]'
                        value={editObjTemp?.patternId || ''}
                        aria-label='Default select example'
                        onChange={(e) => {
                          const selectedPattern = e.target.value;

                          const selectedpattern = PatternsList?.find(
                            (exam) =>
                              exam.patternId ===
                              Number(selectedPattern)
                          );

                          if (selectedpattern) {
                            setEditObjTemp((prev) => ({
                              ...prev,
                              patternId: selectedpattern.patternId,
                            }));
                          }
                          validateField('patternId', selectedPattern);
                        }}
                        onBlur={(e) =>
                          validateField('patternId', e.target.value)
                        }
                      >
                        <option value=''>Select Pattern</option>

                        {PatternsList?.map((obj, index) => (
                          <option key={index} value={obj?.patternId}>
                            {obj?.patternName}
                          </option>
                        ))}
                      </select>
                      {errors.patternId && (
                        <p className='text-red-500 text-sm  text-start'>
                          {errors.patternId}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </div>
                  </div>

                  {questionType === 'single' ? (
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='mx-[auto]'
                    >
                      <p className='mt-[30px]'>Select your Answer</p>

                      <div className='max-w-[600px] mt-[20px] flex-wrap  w-[100%] flex gap-[30px]'>
                        <div className='flex'>
                          <input
                            type='radio'
                            id='option1'
                            name='options'
                            value='option1'
                            checked={
                              editObjTemp.multiAnswers[0] ===
                              'option1'
                            }
                            onChange={handleOptionChange}
                          />
                          <label htmlFor='option1'>
                            {' '}
                            &nbsp;Option 1
                          </label>
                        </div>

                        <div className='flex'>
                          <div>
                            <input
                              type='radio'
                              id='option2'
                              name='options'
                              value='option2'
                              checked={
                                editObjTemp.multiAnswers[0] ===
                                'option2'
                              }
                              onChange={handleOptionChange}
                            />
                            <label htmlFor='option2'>
                              {' '}
                              &nbsp;Option 2
                            </label>
                          </div>
                        </div>

                        <div className='flex'>
                          <div>
                            <input
                              type='radio'
                              id='option3'
                              name='options'
                              value='option3'
                              checked={
                                editObjTemp.multiAnswers[0] ===
                                'option3'
                              }
                              onChange={handleOptionChange}
                            />
                            <label htmlFor='option3'>
                              {' '}
                              &nbsp;Option 3
                            </label>
                          </div>
                        </div>

                        <div className='flex'>
                          <div>
                            <input
                              type='radio'
                              id='option4'
                              name='options'
                              value='option4'
                              checked={
                                editObjTemp.multiAnswers[0] ===
                                'option4'
                              }
                              onChange={handleOptionChange}
                            />
                            <label htmlFor='option4'>
                              {' '}
                              &nbsp;Option 4
                            </label>
                          </div>
                        </div>
                      </div>

                      <p className='mt-[20px]'>
                        Selected option:{' '}
                        {editObjTemp?.multiAnswers
                          ?.map(
                            (answer) =>
                              answer.charAt(0).toUpperCase() +
                              answer.slice(1)
                          )
                          .join(', ')}
                      </p>
                    </div>
                  ) : questionType === 'multiple' ? (
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='mx-auto mt-[30px]'
                    >
                      <p className='mt-[30px]'>
                        Select Multiple Options
                      </p>

                      <div className='max-w-[600px] mt-[20px] flex-wrap w-[100%] flex gap-[30px]'>
                        {options?.map((option) => (
                          <div
                            key={option.id}
                            className='flex items-center'
                          >
                            <label>
                              <input
                                type='checkbox'
                                checked={editObjTemp?.multiAnswers?.includes(
                                  option.label
                                )}
                                onChange={() =>
                                  handleToggle(option.label)
                                }
                              />{' '}
                              {option.label.charAt(0).toUpperCase() +
                                option.label.slice(1)}
                            </label>
                          </div>
                        ))}
                      </div>
                      <p className='mt-[20px]'>
                        Selected Options:{' '}
                        {editObjTemp?.multiAnswers
                          ?.map(
                            (answer) =>
                              answer.charAt(0).toUpperCase() +
                              answer.slice(1)
                          )
                          .join(', ')}
                      </p>
                    </div>
                  ) : (
                    <>
                      <p className='mt-[30px] text-[#757575]'>
                        Solution
                      </p>
                      <CKEditor
                        initData=''
                        onChange={(event) => {
                          let dataa = event.editor.getData();

                          let data = dataa;
                          // let data = updateImageStyles(dataa);

                          console.log('data', data);

                          // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                          data = data
                            .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                            .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                            .replace(/<li>/g, `<span>`)
                            .replace(/<p>/g, `<p>`);

                          // Replace <img> tags with <img> tags and ensure inline style is added
                          data = data
                            .replace(
                              /<img([^>]*?)style="([^"]*?)"([^>]*?)>/g, // Handles cases where style already exists
                              `<img$1style="$2 display:inline;"$3>`
                            )
                            .replace(
                              /<img(?![^>]*?style=")/g, // Handles cases where style attribute is missing
                              `<img style="display:inline;"`
                            );

                          const imageSources = extractImageSrc(data);

                          // Convert base64 array to Blob array
                          const blobs = convertBase64ArrayToBlobs(
                            imageSources,
                            'image/png'
                          );

                          const res = postImageApi(
                            blobs,
                            data,
                            'solution'
                          );
                        }}
                        config={{ ...config, height: '200px' }}
                        onInstanceReady={(event) => {
                          editorRef5.current = event.editor;

                          // Hide all .cke_top elements
                          const topEditorElements =
                            document.querySelectorAll('.cke_top');
                          topEditorElements.forEach(
                            (editorElement) => {
                              editorElement.style.display = 'none';
                            }
                          );

                          const bottomEditorElements =
                            document.querySelectorAll('.cke_bottom');
                          bottomEditorElements.forEach(
                            (editorElementt) => {
                              editorElementt.style.display = 'none';
                            }
                          );
                        }}
                        onBlur={(event) => {
                          const editorInstance = event.editor;
                          const currentData =
                            editorInstance.getData(); // Get the current content of the editor

                          // Validate the field using current editor data
                          validateField('solution', currentData);
                        }}
                      />
                      {errors.solution && (
                        <p className='text-red-500 text-sm'>
                          {errors.solution}
                        </p>
                      )}{' '}
                      {/* Display error */}
                    </>
                  )}

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='mx-[auto]'
                  >
                    <p className='mt-[30px] text-[#757575] flex flex-start'>
                      Question
                    </p>
                    <CKEditor
                      config={{
                        ...config,
                        width: '750px',
                        height: '250px', // Customize height
                      }}
                      onInstanceReady={(event) => {
                        editorRef.current = event.editor; // Save CKEditor instance
                        if (editObj?.question) {
                          event.editor.setData(editObj.question);
                        }
                      }}
                      onChange={(event) => {
                        let dataa = event.editor.getData();

                        let data = dataa;
                        console.log('data', data);

                        // Replace <ol> and <li> tags with <span> tags for inline behavior
                        data = data
                          .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                          .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                          .replace(
                            /<li>/g,
                            `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          )
                          .replace(
                            /<p>/g,
                            `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          );

                        const imageSources = extractImageSrc(data);

                        // Convert base64 array to Blob array
                        const blobs = convertBase64ArrayToBlobs(
                          imageSources,
                          'image/png'
                        );

                        // Call API to upload images
                        const res = postImageApi(
                          blobs,
                          data,
                          'question'
                        );
                      }}
                    />
                  </div>

                  <div className='flex flex-col gap-[20px]'>
                    <div className='flex gap-[15px] mx-[auto] mt-[20px]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 1
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '370px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef1.current = event.editor; // Save CKEditor instance
                            if (editObj?.option1) {
                              event.editor.setData(editObj.option1); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option1'
                            );
                          }}
                        />
                      </div>

                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 2
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '370px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef2.current = event.editor; // Save CKEditor instance
                            if (editObj?.option2) {
                              event.editor.setData(editObj.option2); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option2'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='flex gap-[15px] mx-[auto] mt-[20px]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 3
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '370px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef3.current = event.editor; // Save CKEditor instance
                            if (editObj?.option3) {
                              event.editor.setData(editObj.option3); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option3'
                            );
                          }}
                        />
                      </div>

                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          Option 4
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '370px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef4.current = event.editor; // Save CKEditor instance
                            if (editObj?.option4) {
                              event.editor.setData(editObj.option4); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'option4'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className='mx-[auto]'>
                      <div className='flex flex-col'>
                        <p className='mt-[30px] text-[#757575] flex flex-start'>
                          explanation
                        </p>
                        <CKEditor
                          config={{
                            ...config,
                            width: '370px',
                            height: '200px', // Customize height
                          }}
                          onInstanceReady={(event) => {
                            editorRef4.current = event.editor; // Save CKEditor instance
                            if (editObj?.explanation) {
                              event.editor.setData(
                                editObj.explanation
                              ); // Set initial content
                            }
                          }}
                          onChange={(event) => {
                            let dataa = event.editor.getData();

                            let data = dataa;
                            console.log('data', data);

                            // Replace <ol> and <li> tags with <span> tags for inline behavior
                            data = data
                              .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                              .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                              .replace(
                                /<li>/g,
                                `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              )
                              .replace(
                                /<p>/g,
                                `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                              );

                            const imageSources =
                              extractImageSrc(data);

                            // Convert base64 array to Blob array
                            const blobs = convertBase64ArrayToBlobs(
                              imageSources,
                              'image/png'
                            );

                            // Call API to upload images
                            const res = postImageApi(
                              blobs,
                              data,
                              'explanation'
                            );
                          }}
                        />
                      </div>
                    </div>
                    <button
                      style={{
                        width: 'fit-content',
                        background:
                          '-webkit-linear-gradient(left, #8F94FB, #4E54C8)',
                      }}
                      onClick={() => {
                        if (!loader) {
                          EditFunc();
                        }
                      }}
                      className={`${
                        loader ? 'disabled' : ''
                      } px-[20px] h-[44px] rounded-[5000px] font-medium flex mt-[32px] mx-[auto] justify-center font-light items-center text-[18px] text-[#ffffff]`}
                    >
                      <i
                        className={`fa ${
                          loader ? 'fa-spin fa-spinner' : ''
                        }`}
                      ></i>
                      {loader ? 'Processing' : 'Save'}
                      {/* Submit */}
                    </button>
                    {/* <button
                    style={{
                      width: 'fit-content',
                      background:
                        '-webkit-linear-gradient(left, #8F94FB, #4E54C8)',
                    }}
                    onClick={EditFunc}
                    className=' px-[20px] h-[44px] rounded-[5000px] mx-[auto] font-medium flex mt-[32px] justify-center font-light items-center text-[18px] text-[#ffffff]'
                  >
                    Save
                  </button> */}
                  </div>
                </div>
              </StyledPopupEdit>

              {/* {openEdit && (
              
            )} */}

              {/* Delete Confirmation Popup */}
              <div>
                {open && (
                  <div
                    style={{
                      position: 'fixed',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      // boxShadow:
                      //   'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                      backgroundColor: 'rgba(0,0,0,0.2)',
                      // opacity: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        width: '400px',
                        textAlign: 'center',
                      }}
                    >
                      <h2>Confirm Delete</h2>
                      <p>
                        Are you sure you want to delete this question?
                      </p>
                      <div
                        style={{
                          marginTop: '20px',
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <button
                          onClick={handleClose}
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#EFEFEF',
                            borderRadius: '5px',
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            handleDelete(obj);
                          }}
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#F44336',
                            color: 'white',
                            borderRadius: '5px',
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      ) : (
        <div>
          <p className='text-[22px] mt-[130px] text-center'>
            No Questions Available for this User.
          </p>
        </div>
      )}

      {/* <div className='w-[400px]'>
        <CKEditor
          config={{
            ...config,
            height: '400px', 
          }}
          onInstanceReady={(event) => {
            editorRef.current = event.editor; 
          }}
        />
      </div> */}
    </>
  );
};
export default ViewQuestions;
